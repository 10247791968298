#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.enter-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.enter-card-content {
    padding-left: 5px;
}

.lesson-header {
    margin: 0;
    width: 100%;
    font-size: 18px !important;
    line-height: initial;
}

.lesson-container {
    width: 400px;
}

.chart-container {
    width: 450px;
}

.wp-wrapper .chart-container {
    width: 400px;
    margin: 0 auto;
    margin-top: -15px;
}

@media (max-width: 575.98px) {
    .chart-container, .lesson-container {
        width: 80%;
    }

    .lesson-container {
        max-height: none;
        display: block !important;
    }

    .enter-card-content {
        padding: 8px;
    }

    .enter-button {
        padding-left: 8px;
        margin-bottom: 8px !important;
        justify-content: flex-start;
    }

    .wp-wrapper .chart-container {
        width: 100% !important;
    }
    
    /* .wp-wrapper .word-box {
        margin-top: 0;
    } */
    
    .wp-wrapper .control-container {
        min-width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .wp-wrapper .control {
        padding: 6px;
    }
    
    .wp-wrapper .control-txt {
        width: 45px;
    }
}

/* loader */
.hide {
    display: none;
}

.loader-container {
    position: absolute; /* Stay in place */
    z-index: -1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255, 1); /* Fallback color */
}


@keyframes ldio-ulnb0il8ln {
    0% { transform: translate(12px,80px) scale(0); }
    25% { transform: translate(12px,80px) scale(0); }
    50% { transform: translate(12px,80px) scale(1); }
    75% { transform: translate(80px,80px) scale(1); }
    100% { transform: translate(148px,80px) scale(1); }
}

@keyframes ldio-ulnb0il8ln-r {
    0% { transform: translate(148px,80px) scale(1); }
    100% { transform: translate(148px,80px) scale(0); }
}

@keyframes ldio-ulnb0il8ln-c {
    0% { background: #e15b64 }
    25% { background: #abbd81 }
    50% { background: #f8b26a }
    75% { background: #f47e60 }
    100% { background: #e15b64 }
}

.ldio-ulnb0il8ln div {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transform: translate(80px,80px) scale(1);
    background: #e15b64;
    animation: ldio-ulnb0il8ln 1s infinite cubic-bezier(0,0.5,0.5,1);
}

.ldio-ulnb0il8ln div:nth-child(1) {
    background: #f47e60;
    transform: translate(148px,80px) scale(1);
    animation: ldio-ulnb0il8ln-r 0.25s infinite cubic-bezier(0,0.5,0.5,1), ldio-ulnb0il8ln-c 1s infinite step-start;
}.ldio-ulnb0il8ln div:nth-child(2) {
    animation-delay: -0.25s;
    background: #e15b64;
}.ldio-ulnb0il8ln div:nth-child(3) {
    animation-delay: -0.5s;
    background: #f47e60;
}.ldio-ulnb0il8ln div:nth-child(4) {
    animation-delay: -0.75s;
    background: #f8b26a;
}.ldio-ulnb0il8ln div:nth-child(5) {
    animation-delay: -1s;
    background: #abbd81;
}

.loadingio-spinner-ellipsis-d4plxa73ihf {
    width: 200px;
    height: 200px;
    overflow: hidden;
    position: absolute;
    top: 40%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.ldio-ulnb0il8ln {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
    padding: 15px;
}

.ldio-ulnb0il8ln div { box-sizing: content-box; }

/* Enter Button */
.red-button {
    display: inline-block;
    border-radius: 4px;
    background-color: #79299B;
    border: none;
    color: #FFFFFF;
    text-align: center;
    width: 100px;
    transition: all 0.5s;
    cursor: pointer;
    padding: 4px 8px;
  }
  
  .red-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    font-size: 14px;
  }
  
  .red-button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .red-button:hover span {
    padding-right: 15px;
  }
  
  .red-button:hover span:after {
    opacity: 1;
    right: 0;
  }

/* Back Button */
.blue-button {
    display: inline-block;
    border-radius: 4px;
    background-color: #79299B;
    border: none;
    color: #FFFFFF;
    text-align: center;
    width: 200px;
    
    transition: all 0.5s;
    cursor: pointer;
  }
  
  .blue-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .blue-button span::before {
    content: '\00ab';
    position: absolute;
    opacity: 0;
    top: 0;
    left: -20px;
    transition: 0.5s;
  }
  
  .blue-button:hover span {
    padding-left: 15px;
  }
  
  .blue-button:hover span::before {
    opacity: 1;
    left: 0;
  }